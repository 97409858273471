import Router from "@easyroute/core";
import type { BeforeRouterHook } from "@easyroute/core/dist/types";
import historyMode from "@easyroute/core/history-mode";
import silentMode from "@easyroute/core/silent-mode";
import Error404 from "./pages/Error404.svelte";
import ErrorNoWebGL from "./pages/ErrorNoWebGL.svelte";
// import Overview from "./pages/Overview.svelte";
import { isLoggedIn } from "./services/fetch.api";
import { loading } from "./store/session.store";
import { getSearchParams } from "./utils/url";
import { WebGLCheck } from "./webglCheck";

const beforeEnterDevGuard: BeforeRouterHook = (to, from, next) => {
    // console.log("VITE_UI_MODE", import.meta.env.VITE_UI_MODE);
    if (import.meta.env.VITE_UI_MODE !== "development") {
        next("/");
    } else {
        next();
    }
};

const isInIframe = getSearchParams().some(({ key, value }) => key === "mode" && value === "iframe");

export const router = new Router({
    mode: isInIframe ? silentMode : historyMode,
    omitTrailingSlash: true, // should we remove the last slash in the url,
    routes: [
        {
            path: "/debitors",
            component: () => import("./pages/DebitorsOverview.svelte"),
            name: "Debitors",
        },
        {
            path: "/debitors_selection",
            component: () => import("./pages/DebitorsSelectionOverview.svelte"),
            name: "Debitors",
        },
        {
            path: "/request/:id/:hash_id",
            component: () => import("./pages/RequestMail.svelte"),
            name: "RequestMail",
        },

        {
            path: "/request_sap/:id/:hash_id",
            component: () => import("./pages/RequestSap.svelte"),
            name: "RequestSap",
            beforeEnter: async (to, from, next) => {
                const result = await isLoggedIn();
                if (result === true) {
                    next();
                } else {
                    localStorage.setItem("id", to.params.id);
                    localStorage.setItem("hash_id", to.params.hash_id);
                    next("/login");
                }
            },
        },
        {
            path: "/ui",
            component: () => import("./pages/UI.svelte"),
            name: "UI",
        },
        {
            path: "/order-overview/:id/:hash_id",
            // path: "/order-overview/:hash_id",
            component: () => import("./pages/OrderOverview.svelte"),
            name: "OrderOverview",
        },
        {
            path: "/my_configurations",
            component: () => import("./pages/MyConfigurations.svelte"),
            name: "My Configurations",
        },
        {
            path: "/configurations/:ADRCRMID",
            component: () => import("./pages/DebitorConfigurations.svelte"),
            name: "Debitor Configurations",
        },
        {
            path: "/configurator/:id/:hash_id",
            component: () => import("./pages/Configuration.svelte"),
            name: "3D Configurator",
        },
        {
            path: "/configurator/:id",
            component: () => import("./pages/Configuration.svelte"),
            name: "3D Configurator",
        },
        {
            path: "/login",
            component: () => import("./pages/Login.svelte"),
            name: "Login",
        },
        {
            path: "/passwordreset",
            component: () => import("./pages/Password.svelte"),
            name: "Password",
        },
        {
            path: "/",
            component: () => import("./pages/Overview.svelte"),
            name: "Overview",
            beforeEnter: async (to, from, next) => {
                if (import.meta.env.VITE_UI_MODE !== "development") {
                    window.location.replace(`https://hts-tentiq.com/`);
                } else {
                    next();
                }
            },
        },
        {
            path: "/nowebgl",
            component: ErrorNoWebGL,
            name: "ErrorNoWebGL",
        },
        {
            path: "/*",
            component: Error404,
            name: "Error404",
        },
    ],
});

router.beforeEach((to, from, next) => {
    loading.update(($loading) => {
        $loading.page = true;
        return $loading;
    });
    WebGLCheck(to, from, next);
});

router.afterEach((to, from) => {
    loading.update(($loading) => {
        $loading.page = false;
        return $loading;
    });
});
