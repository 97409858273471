<script lang="ts">
    // Props
    // export let menuItems: UserControlItem[] = [];

    // Imports
    import { router } from "../router";

    import { loc, locSettings, locLocalization } from "../store/loc.store";
    import { findConfiguration } from "../services/fetch.api";
    import { clickOutside } from "../directives/outsideClick";
    import {
        IconSearch,
        IconUserCircle,
        IconLogIn,
        IconLanguage,
        IconUserLight,
        IconLogOut,
        IconMenuHamburger,
        IconPlus,
        IconCloseBig,
    } from "../consts/icons";
    import { userStore, logout, removeDebitor } from "../store/user.store";
    import { showModal_LogInModal } from "../components/modals/ModalManager.svelte";
    import IDInputModal from "./modals/type/IDInputModal.svelte";
    import type { Language } from "shared/lib/localization/loc.types";
    import { getQuery2QueryString, getParam2QueryString } from "../utils/url";
    import { addTrackingEvent } from "../services/tracking.service";
    import { applicationMode } from "../store/mode.store";

    // Interfaces
    interface UserControlItem {
        key: string;
        icon?: string;
        label?: string;
        subMenuItems?: UserControlMenuItem[];
        clickHandler?: (...args: unknown[]) => unknown;
    }

    interface UserControlMenuItem {
        label: string;
        icon?: string;
        spacer?: boolean;
        clickHandler?: (...args: unknown[]) => unknown;
    }

    let menuPositionTop: number;
    let currentlyOpenMenu: string = undefined;

    const setLanguage = (languageKey: Language) => {
        if ($locSettings.language !== languageKey) {
            addTrackingEvent(`switch language ${languageKey}`, `switch language ${languageKey}`);
        }

        locSettings.update((settings) => {
            settings.language = languageKey;
            return settings;
        });

        //close menu
        currentlyOpenMenu = undefined;
    };

    const handleLogin = () => {
        addTrackingEvent("login open modal", "click login button");
        showModal_LogInModal();
    };

    const handleLogout = () => {
        logout();
        currentlyOpenMenu = undefined;
    };

    const handleDebitorsPush = (type: "selection" | "overview") => {
        //get query or param queries
        let queryString = getQuery2QueryString(["id", "hash_id"]);
        queryString = queryString ? queryString : getParam2QueryString(["id", "hash_id"]);

        router.push(`/debitors/${queryString}`);
    };
    const handleDebitorsSelectionPush = (type: "selection" | "overview") => {
        //get query or param queries
        let queryString = getQuery2QueryString(["id", "hash_id"]);
        queryString = queryString ? queryString : getParam2QueryString(["id", "hash_id"]);
        router.push(`/debitors_selection/${queryString}`);
    };

    $: userSubmenuItems =
        $userStore.isLoggedIn === false
            ? []
            : ([
                  {
                      label: $loc.map("locKey_my_area"),
                      icon: IconUserLight,
                      clickHandler: () => {
                          //get query or param queries
                          let queryString = getQuery2QueryString(["id", "hash_id"]);
                          queryString = queryString ? queryString : getParam2QueryString(["id", "hash_id"]);

                          router.push(`/my_configurations/${queryString}`);
                      },
                  },
                  {
                      label: $loc.map("locKey_logout"),
                      icon: IconLogOut,
                      spacer: true,
                      clickHandler: handleLogout,
                  },
                  {
                      label: $loc.map("locKey_debitor_overview"),
                      icon: IconMenuHamburger,
                      clickHandler: handleDebitorsPush,
                  },
                  $userStore.currentDebitor === undefined
                      ? {
                            label: $loc.map("locKey_select_debitor"),
                            icon: IconPlus,
                            clickHandler: handleDebitorsSelectionPush,
                        }
                      : {
                            label: $loc.map("locKey_deselect_debitor"),
                            icon: IconCloseBig,
                            clickHandler: removeDebitor,
                        },
              ] as UserControlMenuItem[]);

    let isIDInputModalVisible = false;
    let searchError = false;

    const search = (search: string) => {
        addTrackingEvent("open search modal", "open search bar");
        const hashId = search.replace(/[#]/g, "");
        findConfiguration(hashId)
            .then((state) => {
                addTrackingEvent("search modal sent", "sent request search bar");
                closeIdInputModal();
                router.push(`/configurator/${state.id}/${hashId}`);
            })
            .catch((error) => {
                searchError = true;
                console.error(error);
            });
    };

    let closeIdInputModal = () => {
        isIDInputModalVisible = false;
        searchError = false;
    };

    $: menuItems = [
        {
            key: "search",
            icon: IconSearch,
            label: "",
            subMenuItems: [],
            clickHandler: () => {
                isIDInputModalVisible = true;
            },
        },
        {
            key: "account",
            icon: $userStore.isLoggedIn ? IconUserCircle : IconLogIn,
            label: $userStore.isLoggedIn ? `${$userStore.firstname} ${$userStore.lastname}` : "",
            subMenuItems: userSubmenuItems,
            clickHandler: $userStore.isLoggedIn ? null : () => handleLogin(),
        },
        {
            key: "language",
            icon: IconLanguage,
            label: $locSettings.language,
            subMenuItems: $locLocalization.map(({ language }) => ({
                label: $loc.map(`lang_${language}`),
                clickHandler: () => setLanguage(language),
            })),
        },
    ] as UserControlItem[];

    export let hiddenMenuItems = [];
</script>

{#if $applicationMode === "standalone"}
    <div class="user-control-component" bind:clientHeight={menuPositionTop}>
        {#each menuItems.filter((menuItem) => !hiddenMenuItems.includes(menuItem.key)) as { key, icon, label, subMenuItems, clickHandler }}
            <div
                class={key}
                class:open={currentlyOpenMenu === key}
                on:click={clickHandler || (() => subMenuItems?.length > 0 && (currentlyOpenMenu = key))}
            >
                {#if icon}
                    {@html icon}
                {/if}

                {#if label}
                    <span>{label}</span>
                {/if}
            </div>

            {#if currentlyOpenMenu === key && subMenuItems && subMenuItems.length > 0}
                <ul
                    class="menu"
                    style="top: {menuPositionTop}px"
                    use:clickOutside
                    on:click-outside={() => (currentlyOpenMenu = undefined)}
                >
                    {#each subMenuItems as { label, icon, spacer, clickHandler: SubMenuClickHandler }}
                        <li on:click={SubMenuClickHandler}>
                            {#if icon}
                                <div class="icon">
                                    {@html icon}
                                </div>
                            {/if}

                            <div class="label">
                                {label}
                            </div>
                        </li>

                        {#if spacer}
                            <li class="spacer" />
                        {/if}
                    {/each}
                </ul>
            {/if}
        {/each}
    </div>
    {#if isIDInputModalVisible}
        <IDInputModal
            error={searchError}
            errorMessage={searchError ? $loc.map("locKey_searched_id_not_found") : ""}
            on:close={closeIdInputModal}
            on:modalAction={({ detail }) => search(detail.payload.id)}
        />
    {/if}
{/if}

<style lang="scss" type="text/scss">
    @use "../scss/vars" as *;
    @use "../scss/rem" as *;

    .user-control-component {
        box-sizing: border-box;
        border-radius: rem($radius);
        color: var(--color-secondary-88);
        display: flex;
        align-items: center;

        & > *:not(.menu) {
            border-radius: rem($radius);
            min-height: rem(25);
            padding: 0 rem(6);
            display: flex;
            align-items: center;

            span {
                font-weight: $font-weight-regular;
                font-size: rem(12);
                color: var(--color-primary-100);
                padding-left: rem(6);
            }

            &.open,
            &:hover {
                background-color: var(--color-secondary-15);
                cursor: pointer;
            }
        }

        :global {
            svg {
                width: rem(20);
                height: rem(20);
                path {
                    fill: var(--color-primary-100);
                }
            }
        }

        .language {
            span {
                font-size: rem(16);
                text-transform: uppercase;
                color: var(--color-primary-100);
                margin-left: rem(2);
            }
        }

        ul.menu {
            list-style-type: none;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
            background-color: var(--default-background-color);
            padding: rem(6) rem(4);
            margin: 0;
            position: absolute;
            right: rem(10);
            display: grid;
            grid-template-columns: min-content auto;

            :global {
                svg {
                    width: rem(12);
                    height: rem(12);
                    path {
                        fill: var(--color-primary-100);
                    }
                }
            }

            li {
                display: contents;

                .icon,
                .label {
                    padding: rem(6);
                    display: flex;
                    align-items: center;

                    &.label {
                        color: var(--color-primary-100);
                        grid-column-start: 2;
                    }
                }

                &:hover {
                    .icon,
                    .label {
                        background-color: var(--color-secondary-15);
                        cursor: pointer;
                    }
                }

                &.spacer {
                    &:before,
                    &:after {
                        content: "";
                        display: block;
                        background-color: var(--color-secondary-25);
                        height: 1px;
                        margin: rem(6) 0;
                    }

                    &:before {
                        padding-left: 0;
                        margin-left: rem(6);
                    }

                    &:after {
                        padding-right: 0;
                        margin-right: rem(6);
                    }
                }
            }
        }
    }
</style>
