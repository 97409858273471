<script lang="ts">
    import "material-design-iconic-font/dist/css/material-design-iconic-font.css";
    import "normalize.css/normalize.css";
    import "./scss/global.scss";

    import { EasyrouteProvider, RouterOutlet } from "@easyroute/svelte";
    import { router } from "./router";
    import { backdropActive } from "./components/modals/Modal.svelte";
    import ModalManager from "./components/modals/ModalManager.svelte";
    import TagManager from "./components/TagManager.svelte";
    import DebugSidebar from "./components/DebugSidebar.svelte";
    import PreloaderAtom from "./atoms/PreloaderAtom.svelte";
    import { loading } from "./store/session.store";
    import Tracking from "./Tracking.svelte";
    import { isReseller } from "./consts/reseller";

    const SHOW_DEBUG_UI: boolean = import.meta.env.VITE_SHOW_DEBUG_UI === "true";
    const ENABLE_TRACKING: boolean = import.meta.env.PROD === true && !isReseller;
</script>

<TagManager />

{#if ENABLE_TRACKING}
    <Tracking />
{/if}

<EasyrouteProvider {router}>
    <RouterOutlet />

    {#if $loading.page && !$loading.mutation}
        <PreloaderAtom />
    {/if}
</EasyrouteProvider>

{#if $backdropActive}
    <div class="backdrop" />
{/if}

<ModalManager />

{#if SHOW_DEBUG_UI}
    <DebugSidebar />
{/if}

<style lang="scss" type="text/scss">
    :global(#hts-app .easyroute-outlet) {
        display: contents;

        // ↓↓↓ Fallback for contents
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 490;
        background-color: var(--color-primary-100);
        opacity: 0.6;
    }
</style>
