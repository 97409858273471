<script lang="ts">
    import { userRegister } from "../../../services/fetch.api";

    /**
     * Components
     */
    import Modal from "../Modal.svelte";
    import { loc } from "../../../store/loc.store";
    import { session_context } from "../../../store/session.store";
    import { logoAlt, logoLink, logoPath } from "../../../consts/reseller";
    import InputAtom from "../../../atoms/InputAtom.svelte";
    import ButtonAtom from "../../../atoms/ButtonAtom.svelte";

    let windowsUsername: string = "";
    let registrationDone: boolean = false;

    const onSubmit = () => {
        userRegister(windowsUsername, $session_context.sales_country.value)
            .then((wasSuccessful) => {
                registrationDone = wasSuccessful;
            })
            .catch((error: Error) => console.error(error));
    };

    const reSubmit = () => {
        onSubmit();
    };
</script>

<Modal backdrop={true} let:close>
    <svelte:fragment slot="header">
        <h2>{$loc.map("locKey_registration")}</h2>
    </svelte:fragment>

    <img src={logoPath} alt={logoAlt} class="modal-logo" />

    {#if registrationDone}
        <p>{$loc.map("locKey_user_registration_done_text")}</p>

        <div class="button-group">
            <ButtonAtom color="secondary" on:click={close}>{$loc.map("locKey_close")}</ButtonAtom>
            <ButtonAtom color="primary" on:click={reSubmit}>
                <i class="zmdi zmdi-refresh-sync" />
                {$loc.map("locKey_send_again")}
            </ButtonAtom>
        </div>
    {:else}
        <p>{$loc.map("locKey_user_registration_text")}</p>

        <form on:submit|preventDefault={() => onSubmit()}>
            <InputAtom
                id="windows-username"
                bind:value={windowsUsername}
                placeholder={$loc.map("locKey_modal_windows_username")}
            >
                <i slot="prefix" class="zmdi zmdi-lock" />
            </InputAtom>
        </form>
    {/if}

    <svelte:fragment slot="footer">
        <ButtonAtom color="secondary" size="small" on:click={close}>
            {$loc.map("locKey_cancel")}
        </ButtonAtom>

        <ButtonAtom color="primary" size="small" on:click={onSubmit}>
            {$loc.map("locKey_register")}
            <i class="zmdi zmdi-long-arrow-right" />
        </ButtonAtom>
    </svelte:fragment>
</Modal>

<style lang="scss">
    @use "../../../scss/vars" as *;
    @use "../../../scss/rem" as *;

    h2 {
        text-align: center;
    }

    .modal-logo {
        height: rem(25);
        align-self: flex-start;
    }

    p {
        margin-bottom: rem(8);
        font-size: rem(12);
        font-weight: $font-weight-regular;
        line-height: 1.5;
    }

    form {
        display: flex;
        flex-direction: column;
        gap: rem(10);

        :global(button) {
            margin-left: auto;
        }
    }

    .button-group {
        margin-top: rem(40);
        margin-left: auto;
        display: flex;
        justify-content: flex-end;
    }
</style>
