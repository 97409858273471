<script lang="ts">
    import { loc, locSettings } from "../store/loc.store";
    import { router } from "../router";

    import Layout from "../components/Layout.svelte";
    import Modal from "../components/modals/Modal.svelte";
    import UserControlComponent from "../components/UserControlComponent.svelte";
    import ButtonAtom from "../atoms/ButtonAtom.svelte";
    import { isReseller, logoAlt, logoLink, logoPath } from "../consts/reseller";
</script>

<div class="error-404">
    <Layout size="full" headerFloating={false}>
        <svelte:fragment slot="header_left">
            <img src={logoPath} alt={logoAlt} class="logo" />
            {#if isReseller}
                {$loc.map("locKey_powered_by_hts")}
            {/if}
        </svelte:fragment>

        <svelte:fragment slot="header_right">
            <UserControlComponent hiddenMenuItems={["search", "account"]} />
        </svelte:fragment>

        <Modal closeable={false} let:close applyErrorStyle={true}>
            <svelte:fragment slot="header">
                <h2>{$loc.map("locKey_error_404_title")}</h2>
            </svelte:fragment>

            {@html $loc.map("locKey_error_404_text")}

            <svelte:fragment slot="footer">
                <ButtonAtom
                    on:click={() => {
                        router.push(`/`);
                    }}
                    color="primary"
                >
                    {$loc.map("locKey_goto_configurator")}
                </ButtonAtom>
            </svelte:fragment>
        </Modal>
    </Layout>
</div>

<style lang="scss" type="text/scss">
    @use "../scss/vars" as *;
    @use "../scss/rem" as *;

    .error-404 {
        :global {
            .layout {
                background-color: var(--color-primary-4);

                .header {
                    z-index: 510;
                }
            }
        }
    }
</style>
