<script lang="ts">
    import { loc } from "../store/loc.store";
    import { userStore } from "../store/user.store";
</script>

<div class="banner {$userStore.department}">
    <div class="department">{$loc.map("locKey_department_" + $userStore.department)}</div>
</div>

<style lang="scss" type="text/scss">
    @use "../scss/vars.scss" as *;
    @use "../scss/rem.scss" as *;
    .banner {
        display: flex;
        padding: rem(9) rem(18);
        align-items: center;
        font-family: "Noto Sans", "Ubuntu", sans-serif;
        font-size: rem(10);
        font-weight: 700;

        &.sales {
            background-color: var(--signal);
            color: var(--signal-dark);
        }

        &.calculation {
            background-color: var(--color-calculation-24);
            color: var(--color-calculation-100);
        }
    }
</style>
